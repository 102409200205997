import { Menu, MenuContent, MenuList, MenuItem as MenuItemEl, Tooltip, Button, Dropdown, DropdownList, DropdownItem } from "@patternfly/react-core";
import { useEffect, useRef, useState } from "react";
import ConfigIcon from "@patternfly/react-icons/dist/js/icons/cogs-icon";
import { useLocation, useNavigate } from "react-router-dom";
import {  ProcessAutomationIcon } from "@patternfly/react-icons";
import LicenseIcon from "@patternfly/react-icons/dist/js/icons/key-icon";
import { GrafanaIcon, KafkaIcon, PrometheusIcon } from "./DashboardIcons";
import './dashboard.css';
import { LicenseService } from "../../api/LicenseService";
import { useLicenseStore } from "../../api/LicenseStore";
import {SmhAuthenticationUtil} from "../common/SmhAuthenticationUtil";

export default ({ page, pageId, isLicenseValid, showUser, setPageId }: any) => {
    const toggleRef = useRef<HTMLButtonElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isToolTipShow, setToolTip] = useState(true);
    const submenuRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const subMenuList = ['/license', '/configuration', '/configuration/dashboard/prometheus-iframe', '/configuration/dashboard/grafana-iframe', '/configuration/dashboard/kafka-iframe']
    useEffect(() => {
        setIsOpen(false);
        setToolTip(true);
    }, [pageId, showUser])
    const subNavbarClick = async (navLink: string) => {
        if(navLink === 'configuration/dashboard/grafana-iframe'){
           let fullURL = `${window.location.protocol}//${window.location.hostname}`;
           if(window.location.hostname === 'localhost') {
              fullURL = fullURL+':3939/';
           } else {
              fullURL = fullURL+'/grafana/login';
           }
           window.open(fullURL, '_blank');
           setIsOpen(false);
           return;
        } else if(navLink === 'configuration/dashboard/kafka-iframe'){
            let fullURL = `${window.location.protocol}//${window.location.hostname}`;
            if(window.location.hostname === 'localhost') {
               fullURL = fullURL+':8989/';
            } else{
               fullURL = fullURL+'/kafka';
            }
            window.open(fullURL, '_blank');
            setIsOpen(false);
            return;
        }
        if (navLink === 'license') {
            await LicenseService.getLicense()
                .then(res => {
                    const [err, _license] = res;
                    if (err == null && _license != null) {
                        useLicenseStore.setState({ license: _license!!, operation: "none" })
                    }
                });
        }
        setPageId(navLink);
        navigate(navLink);
        setIsOpen(false);
        setToolTip(true);
    }
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            try {
                if(event && event?.target  && event.target?.closest && typeof event.target.closest === 'function') {
                    const svgElement = event?.target?.closest('svg');
                    if(svgElement && svgElement?.getAttribute('class')){
                        const isSameParentElement = svgElement?.getAttribute('class')?.indexOf('config-custom-icons') || false;
                        if (toggleRef?.current && !toggleRef.current.contains(event?.target as Node) && !isSameParentElement) {
                            setIsOpen(false);
                        }
                    } 
                }
            } catch (error:any) {
                setIsOpen(false);
                console.error(`Error: ${error?.message}`);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const getActiveUrlClass = (isSubMenu: boolean = false, flag='') => {
        if(isSubMenu) {
            return  flag === location.pathname ?  'sub-nav-button-selected' : '';
        }
        const isPathMatched = subMenuList.some((url) => url.indexOf(location.pathname) > -1);
        if (isPathMatched) {
            return 'nav-button-selected';
        }
        return '';
    }
    return (<div style={{ position: "relative", display: "inline-block", height: '90px', marginTop: '-7px' }} className='configuration-menu-items'>
        <Menu>
            <MenuContent>
                <MenuList>
                    {/* Main menu item with submenu */}
                    <MenuItemEl
                        ref={toggleRef}
                        onClick={() => { setIsOpen(!isOpen); setToolTip(false); }}
                        style={{ cursor: "pointer", position: "relative" }}
                        className={`${getActiveUrlClass()} configuration-menu-item-element`}
                    >
                        {
                            isToolTipShow && (<Tooltip content={page.tooltip} position={"right"} >
                                <Button id={page.pageId} icon={<ConfigIcon className="config-custom-icons" />} variant={"plain"}
                                    style={{ height: '50px', width: '65px' }}
                                />
                            </Tooltip>)
                        }
                        {
                            !isToolTipShow && (<Button id={page.pageId} icon={<ConfigIcon className="config-custom-icons" />} variant={"plain"}
                                style={{ height: '50px', width: '65px' }}
                            />)
                        }
                    </MenuItemEl>
                </MenuList>
            </MenuContent>
            {isOpen && (
                <div
                    className='configuration-menu-open-toggle'
                    ref={submenuRef}
                    style={{
                        position: "absolute",
                        top: toggleRef.current?.offsetTop || (isLicenseValid() ? -100 : 0),
                        left: toggleRef.current?.offsetWidth || 100, // Position to the right
                        zIndex: 1000, // Ensure it's above other elements
                        background: "white",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)"
                    }}
                >
                    <Dropdown
                        isOpen={isOpen}
                        ref={toggleRef} // Ensures submenu is aligned with main item
                        style={{ position: "absolute", left: "100%", top: "0", marginLeft: "5px", alignItems: 'right' }} // Fine-tunes position
                        toggle={(_) => <></>}
                    >
                        <DropdownList className='config-open-menu-list'>
                            <DropdownItem style={{ background: 'white' }} key="1" onClick={() => subNavbarClick('license')} className={`${getActiveUrlClass(true, '/license')}`}>
                                <Tooltip content={'License Management'} position={"right"}>
                                    <Button  id={'license'} icon={<LicenseIcon style={{ color: 'black' }} />} variant={"plain"}
                                        style={{ height: '50px', width: '65px' }}
                                    />
                                </Tooltip>
                            </DropdownItem>
                            {
                                isLicenseValid() && (<>
                                    <DropdownItem style={{ background: 'white' }} key="2" onClick={() => subNavbarClick('configuration')}  className={`${getActiveUrlClass(true, '/configuration')}`}>
                                        <Tooltip content={'Configuration'} position={"right"}>
                                            <Button
                                                id={'configuration'} icon={<ProcessAutomationIcon style={{ color: 'black' }} />} variant={"plain"}
                                                style={{ height: '50px', width: '65px' }}
                                            />
                                        </Tooltip>
                                    </DropdownItem>
                                    {!SmhAuthenticationUtil.isAuthenticatedUserViewerOnly() && (<>
                                        <DropdownItem style={{ background: 'white' }} key="4" onClick={() => subNavbarClick('configuration/dashboard/prometheus-iframe')}  className={`${getActiveUrlClass(true,'/configuration/dashboard/prometheus-iframe')}`}>
                                            <Tooltip content={'Prometheus'} position={"right"}>
                                                <Button id={'prometheus'} icon={PrometheusIcon} variant={"plain"}
                                                    style={{ height: '50px', width: '65px' }}
                                                />
                                            </Tooltip>
                                        </DropdownItem>
                                        <DropdownItem style={{ background: 'white' }} key="5" onClick={() => subNavbarClick('configuration/dashboard/grafana-iframe')} className={`${getActiveUrlClass(true, '/configuration/dashboard/grafana-iframe')}`} >
                                            <Tooltip content={'Grafana'} position={"right"}>
                                                <Button id={'grafana'} icon={GrafanaIcon} variant={"plain"}
                                                    style={{ height: '50px', width: '65px' }}
                                                />
                                            </Tooltip>
                                        </DropdownItem>
                                        <DropdownItem style={{ background: 'white' }} key="6" onClick={() => subNavbarClick('configuration/dashboard/kafka-iframe')} className={`${getActiveUrlClass(true, '/configuration/dashboard/kafka-iframe')}`} >
                                            <Tooltip content={'Kafka'} position={"right"}>
                                                <Button id={'kafka'} icon={KafkaIcon} variant={"plain"}
                                                    style={{ height: '50px', width: '65px' }}
                                                />
                                            </Tooltip>
                                        </DropdownItem>
                                    </>)}
                                </>)
                            }
                        </DropdownList>
                    </Dropdown>
                </div>
            )}
        </Menu>
    </div>)
}

