import { Button, PageSection, Spinner, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core"
import { MainToolbar } from "../../designer/MainToolbar"
import { useEffect, useMemo, useState } from "react";
import Iframe from "react-iframe";
import { useParams } from "react-router-dom";
export default () => {
    const { iframeName } = useParams();
    const hostname =  window.location.hostname;
    const [iframeUrl, setIframeUrl] = useState(`${hostname}/prometheus`)
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    useEffect(() => {
        setLoading(true);
        setIframeUrl('');
        let fullURL = `${window.location.protocol}//${window.location.hostname}`;
        if (iframeName === 'grafana-iframe') {
            setTitle('Grafana Dashboard');
            if(window.location.hostname === 'localhost') {
                fullURL = `${fullURL}:3939/grafana/login`
            } else {
                fullURL = `${fullURL}/grafana/login`
            }
            setTimeout(() =>{setIframeUrl(fullURL);setLoading(true);}, 500);
        }
        else if(iframeName === 'kafka-iframe'){
            setTitle('Kafka Dashboard');
            if(window.location.hostname === 'localhost') {
                fullURL = `${fullURL}:8989/`
            } else {
                fullURL = `${fullURL}/kafka`
            }
            setTimeout(() =>{setIframeUrl(fullURL);setLoading(true);}, 500);
        }
        else {
            setTitle('Prometheus Dashboard');
            if(window.location.hostname === 'localhost') {
                fullURL = `${fullURL}:9393/`
            } else {
                fullURL = `${fullURL}/prometheus`
            }
            setTimeout(() =>{setIframeUrl(fullURL);setLoading(true);}, 500);
        }
    }, [iframeName]);
    const elements: any[] = [<ToolbarItem key={0}>
        <Button
           style={{height: '33px'}}
            variant="link"
            title="Refresh"
        />
    </ToolbarItem>];
    const toolsList = useMemo(
        () => (
            <Toolbar id="toolbar-group-types">
                <ToolbarContent>{elements}</ToolbarContent>
            </Toolbar>
        ),
        []
    );
    return (
        <>
           <MainToolbar title={<h2 className="dashboard-title-custom">{`${title}`}</h2>} tools={toolsList} /> 
                <PageSection className="iframe-configuration-menu"  key={iframeName} style={{paddingTop: '0px'}}>
                { loading && (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1000000,
                            }}
                        >
                            <Spinner size="xl" />
                        </div>
                    )}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        maxWidth: "100%",
                        height: "85vh",
                        overflow: "hidden",
                    }}
                >
                    <Iframe scrolling="yes" url={iframeUrl} width="100%" styles={{ height: '83vh', maxHeight: '83vh', overflow: 'auto' }} onLoad={() => setLoading(false)}></Iframe>
                </div>
            </PageSection>
        </>
    )
}